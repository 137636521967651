<template>
  <div class="about">
    <div class="top-content">
      <div class="mail-hint" v-if="showEmailHint">
        <span class="text">🙏🏻</span>
        <span class="text">
          我们已将电子邮件发送到你电子邮件地址，请单击链接以验证你的电子邮件。
        </span>
        <img src="~@/assets/ic_thick.svg" class="icon" @click="hideHint" />
      </div>
      <div class="main-content">
        <div>
          <div class="main-title">
            <img src="@/assets/logo_iforestfm@2x.png" width="544" />
          </div>
          <div class="play-button">
            <div class="play-button-bg"></div>
            <div class="play-button-content" @click="goToHome">
              探索森林的声音
            </div>
          </div>
        </div>
      </div>
      <div class="footer-content">
        <div class="footer-content-main">
          你可以在这里探索世界各地的森林的声音，在这放松，逃避喧嚣
        </div>
        <div class="footer-content-desc">
          由SLEEPON和 Sounds of the Forest带给你
        </div>
        <div class="footer-content-about" @click="scrollBody">关于我们</div>
      </div>
    </div>
    <div class="about-content">
      <div class="about-section">
        <div class="about-section-left">
          <div class="section-title">还记得森林吗？</div>
          <div class="section-text">
            <div>
              世界各地的人们都记录了他们森林的声音，让您无论身在何处都可以逃离大自然，放松身心。深吸一口气，沉浸在森林的声音中，因为它们呼吸着生命和美丽！
            </div>
          </div>
          <div class="section-footer">
            <a
              href="http://ubkare.com/privacy.html"
              rel="noreferrer noopener"
              target="_blank"
            >
              隐私协议 </a
            >和<a
              href="http://ubkare.com/service.html"
              rel="noreferrer noopener"
              target="_blank"
            >
              服务条款 </a
            ><br />
            <div class="version-text">版本：V1.0.3</div>
          </div>
        </div>
        <div class="about-section-right"></div>
      </div>
      <div class="about-section-bottom">
        <div class="section-title">声音</div>
        <div class="section-text">
          iForest FM项目中使用的音频文件是同
          <a
            style="text-decoration: underline"
            href="https://timberfestival.org.uk/soundsoftheforest-soundmap/"
            rel="noreferrer noopener"
            target="_blank"
            >Sounds of the Forest</a
          >
          合作并提供，在此我们由衷感谢 Sounds of the Forest
        </div>
        <div class="about-album">
          <img src="@/assets/album@2x.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import router from "@/router";
import { scrollBody } from "@/util";
import { mapState } from "vuex";

export default defineComponent({
  components: {},
  computed: mapState({
    showEmailHint: (state) => state.showEmailHint,
  }),
  methods: {
    hideHint() {
      this.$store.commit("HIDE_EMAIL_HINT");
    },
  },
  setup() {
    return {
      scrollBody,
      goToHome() {
        router.push("/player");
      },
    };
  },
});
</script>

<style lang="less" scoped>
.top-content {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: no-repeat url("https://file.sleepon.cn/fm/bg_09-tuya.jpg") center
    center / cover;
}

.main-content {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  text-align: center;

  .main-title {
    font-weight: 800;
    font-size: 100px;
    color: #fff;
    margin-bottom: 60px;
  }

  .play-button {
    display: inline-block;
    position: relative;
    border-radius: 16px;
    color: #fff;
    border: 1px solid transparent;
    background-clip: padding-box;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      margin: -1px; /* !importanté */
      border-radius: inherit; /* !importanté */
      background: linear-gradient(to right, #fff, transparent);
    }

    .play-button-bg {
      z-index: 2;
      position: absolute;
      width: 100%;
      height: 100%;
      background: no-repeat url("https://file.sleepon.cn/fm/bg_09-tuya.jpg")
        center center;
      backdrop-filter: blur(20px);
      filter: blur(20px);
      border-radius: 16px;
    }
    .play-button-content {
      position: relative;
      z-index: 3;
      cursor: pointer;
      color: #fff;
      text-decoration: none;
      padding: 16px 22px 18px 62px;
      background: no-repeat url("~@/assets/ic_play.svg") left 22px center;
    }
  }
}

.footer-content {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-size: 24px;
  color: #fff;

  &-main {
    margin-bottom: 18px;
  }

  &-desc {
    font-size: 18px;
    margin-bottom: 40px;
  }

  &-about {
    display: inline-block;
    font-size: 30px;
    padding-left: 62px;
    background: no-repeat url("~@/assets/mouse.svg") left center;
    margin-bottom: 25px;
    line-height: 48px;
    cursor: pointer;
  }
}

.about-content {
  margin-top: 100vh;
  position: relative;
  z-index: 2;
  background: #fff;
}

.about-section {
  display: flex;
  flex-flow: row nowrap;
  padding: 116px 165px;
  .about-section-left {
    position: relative;
    .section-text {
      overflow: hidden;
      border-radius: 16px;
      position: relative;
      z-index: 2;
      margin-right: -100px;
      padding: 17px 30px 54px 0;

      &:before {
        content: "";
        background: rgba(255, 255, 255, 0.4);
        backdrop-filter: blur(20px);
        filter: blur(20px);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
      }
    }
    flex: 1 1 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
  }
  .about-section-right {
    flex: 0 0 588px;
    border-radius: 32px;
    // 1448 * 812
    // 588 * 537
    @x: round((1448 - 588) / 2);
    @y: round((812 - 537) / 2);
    background: no-repeat
      url("https://file.sleepon.cn/fm/bg_09-tuya.jpg?x-oss-process=image/crop,x_@{x},y_@{y},w_588,h_537")
      center center / cover;
    min-height: 537px;
    padding-left: 100px;
    box-sizing: border-box;
  }
}
.about-section-bottom {
  text-align: center;
  padding: 62px 0;
  background: #f5f9fa;
  .section-text {
    max-width: 55vw;
    margin-left: auto;
    margin-right: auto;
  }
  .about-album {
    margin-top: 60px;
    img {
      width: 149px;
      height: auto;
    }
  }
}

.section-title {
  font-weight: 600;
  font-size: 48px;
  margin-bottom: 35px;
}
.section-text {
  font-size: 24px;
  line-height: 34 / 24;
  a {
    color: currentColor;
  }
}
.section-footer {
  font-size: 24px;
  margin-top: auto;
  a {
    color: currentColor;
  }

  .version-text {
    padding-top: 10px;
    font-weight: normal;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.2);
  }
}

.mail-hint {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  width: 340px;
  display: flex;
  flex-flow: row nowrap;
  position: absolute;
  top: 100px;
  left: 50%;
  margin-left: -170px;
  z-index: 999;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    backdrop-filter: blur(20px);
    filter: blur(20px);
  }

  padding: 16px 20px;

  .text {
    margin-right: 15px;
    color: #ffffff;
    font-size: 14px;
  }

  .icon {
    cursor: pointer;
  }
}
</style>
