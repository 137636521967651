<template>
  <div class="page-forget-password">
    <div class="modal-content">
      <img src="@/assets/ic_playing.svg" class="modal-icon" />
      <template v-if="step === 1">
        <div class="modal-title">忘记密码</div>
        <div class="modal-desc">我们会向你的手机发送验证码来重置你的密码</div>
        <div class="switch-tab">
          <div
            :class="['switch-tab-item', { active: findType === 'phone' }]"
            @click="
              () => {
                this.findType = 'phone';
                this.resetFields();
              }
            "
          >
            手机
          </div>
          <div
            :class="['switch-tab-item', { active: findType === 'email' }]"
            @click="
              () => {
                this.findType = 'email';
                this.resetFields();
              }
            "
          >
            邮箱
          </div>
        </div>
        <a-form layout="horizontal" v-if="findType === 'phone'">
          <a-form-item label="" hasFeedback v-bind="validateInfos.phone">
            <a-input
              :placeholder="'输入手机'"
              v-model:value="modelRef.phone"
              size="large"
            />
          </a-form-item>
        </a-form>
        <a-form layout="horizontal" v-if="findType === 'email'">
          <a-form-item label="" hasFeedback v-bind="validateInfos.email">
            <a-input
              :placeholder="'输入邮箱'"
              v-model:value="modelRef.email"
              size="large"
            />
          </a-form-item>
        </a-form>
        <i-button type="primary" style="width: 100%" @click="findPassword"
          >下一步</i-button
        >
      </template>
      <template v-if="findType === 'phone' && step === 2">
        <div class="modal-title">输入验证码</div>
        <div class="vcode-text">
          我们已经将4位验证码发送到你的手机 +86 {{ modelRef.phone }}
        </div>
        <a-form layout="horizontal">
          <a-form-item label="" hasFeedback v-bind="validateInfos.code">
            <a-input placeholder="验证码" v-model:value="modelRef.code" />
          </a-form-item>
        </a-form>
        <div class="link-text" @click="handleResend">
          {{ time === 0 ? "没有收到验证码?" : time + "s" }}
        </div>
        <i-button
          type="primary"
          style="width: 100%"
          @click="nextStep"
          class="bottomButton"
          >下一步</i-button
        >
      </template>
      <template v-if="findType === 'email' && step === 2">
        <div class="modal-title">检查你的邮箱</div>
        <div class="vcode-text">
          已经向你的电子邮箱发送了邮件，<br />
          请按照邮件指示操作
        </div>
        <br>
        <i-button
          type="primary"
          style="width: 100%"
          @click="toLogin"
          class="bottomButton"
          >返回登录</i-button
        >
      </template>
      <template v-if="findType === 'phone' && step === 3">
        <div class="modal-title">设置新的密码</div>
        <div class="vcode-text">密码最少6个字符</div>
        <a-form layout="horizontal">
          <a-form-item label="" hasFeedback v-bind="validateInfos.password">
            <a-input-password
              placeholder="密码"
              v-model:value="modelRef.password"
            />
          </a-form-item>
        </a-form>
        <i-button
          type="primary"
          style="width: 100%"
          @click="resetPassword"
          class="bottomButton"
          >重置密码</i-button
        >
      </template>
    </div>
  </div>
</template>

<script>
import sha1 from "sha1";
import { reactive, nextTick } from "vue";
import { Form } from "ant-design-vue";
import { post, get, put, userServer } from "@/util";

const useForm = Form.useForm;

export default {
  name: "ForgetPassword",

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      step: 1,
      time: 0,
      findType: "phone",
    };
  },

  methods: {
    findPassword() {
      const findType = this.findType;

      if (findType === "phone") {
        this.validate(["phone"]).then((values) => {
          this.sendCode({
            type: 2,
            area_code: "86",
            phone: values.phone,
          });
        });
      } else {
        this.validate(["email"]).then((values) => {
          this.sendCode({
            type: 1,
            email: values.email,
          });
        });
      }
    },

    nextStep() {
      this.validate(["code"]).then((values) => {
        this.step = 3;
      });
    },

    resetPassword() {
      this.validate(["password"]).then((values) => {
        const { phone, password, code } = this.modelRef;
        put(userServer + "/v1/api/back/password/sms", {
          area_code: "86",
          phone,
          code,
          password: sha1(password),
          confirm_password: sha1(password),
        }).then(() => {
          this.$message.info({
            durtaion: 5,
            content: "密码重置成功,请重新登录",
            onClose: () => {
              this.$router.push("/login");
            },
          });
        });
      });
    },
    toLogin() {
      this.$router.push("/login");
    },
    timer() {
      clearTimeout(this.$timer);
      if (this.time > 0) {
        this.$timer = setTimeout(() => {
          this.time -= 1;
          this.timer();
        }, 1000);
      }
    },

    sendCode(params) {
      return post(userServer + "/v1/api/code", {
        logic: 3,
        ...params,
      }).then(
        () => {
          this.time = 60;
          this.timer();
          this.step = 2;
          return { data: true };
        },
        (data) => {
          return { data: false };
        }
      );
    },

    async handleResend() {
      if (this.time === 0) {
        this.sendCode({
          area_code: "86",
          phone: this.modelRef.phone,
        });
      }
    },

    afterClose() {
      this.step = 1;
      this.time = 0;
      this.loginType = "password";
      this.resetFields();
    },
  },

  setup(props, context) {
    const modelRef = reactive({
      loginName: "",
      phone: "",
      email: "",
      password: "",
      code: "",
    });

    const rulesRef = reactive({
      phone: [
        {
          required: true,
          message: "请输入手机号码",
        },
        {
          pattern:
            /^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/,
          message: "请输入有效的手机",
        },
      ],
      email: [
        {
          required: true,
          message: "请输入邮箱",
        },
        {
          type: "email",
          message: "请输入有效的邮箱",
        },
      ],
      password: [
        {
          required: true,
          message: "请输入密码",
        },
        {
          min: 6,
          message: "密码最少6个字符",
        },
        {
          max: 32,
          message: "密码不能超过32个字符",
        },
      ],
      code: [
        {
          required: true,
          message: "请输入验证码",
        },
      ],
    });

    const { validate, validateInfos, resetFields } = useForm(
      modelRef,
      rulesRef
    );

    return {
      validate,
      validateInfos,
      modelRef,
      resetFields,
    };
  },
};
</script>

<style lang="less" scoped>
.modal-content {
  display: flex;
  flex-flow: column nowrap;
}
.modal-desc {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 15px;

  color: rgba(255, 255, 255, 0.54);
}

.modal-title {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #ffffff;
}
.modal-icon {
  width: 50px;
  margin-bottom: 15px;
}

.link-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #1cca96;
  cursor: pointer;
  margin-bottom: 20px;
}

.vcode-text {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  margin-bottom: 20px;
}

.switch-tab {
  margin-bottom: 20px;
  &-item {
    cursor: pointer;
    display: inline-block;
    font-size: 24px;
    color: rgba(255, 255, 255, 0.6);
    margin-right: 32px;
    &.active {
      font-weight: 600;
      color: #ffffff;
    }
  }
}
</style>

<style lang="less" scoped>
.page-forget-password {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 80px;
  background: #202828;
}
.modal-content {
  width: 327 / 1440 * 100vw;
  margin-left: auto;
  margin-right: auto;
}
</style>
